//@ts-ignore @eslint-ignore

import { BooleanMap } from "../../../../types/Objects";

//ONLY WORKS FOR 1:1 MAPPINGS OF KEY:VALUE
export const getKeyByValue = (object: object, value: any): any => {
  //@ts-ignore @eslint-ignore
  return Object.keys(object).find((key) => object[key] === value);
};

export const isFunction = (value: any): boolean => checkTypeOf(value, "function");

export const isObject = (value: any): boolean => checkTypeOf(value, "object");

export const checkTypeOf = (value: any, type: string): boolean => typeof value === type;

export const areObjectsEqual = (obj1?: any, obj2?: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const removeNullFields = (obj: object) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const checkAllFalseAndSetFirstTrue = (map: BooleanMap): BooleanMap => {
  const keys = Object.keys(map);
  if (keys.length === 0) {
    return map;
  }
  const values = Object.values(map);
  const allFalse = values.every((value) => !value);
  if (allFalse) {
    map[keys[0]] = true;
  }
  return map;
};
