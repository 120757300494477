import styled from "styled-components";
import { ModalBodyThemeType } from "types";

type ModalBodyThemeStyles = {
  styles: ModalBodyThemeType;
};

export const ModalBodyDiv = styled.div.attrs(() => ({
  className: "bcr-modal-body",
}))<ModalBodyThemeStyles>`
  &&& {
    background-color: ${(props) => props.styles.background};
    color: ${(props) => props.styles.fontColor};
    position: relative;
    flex: 1 1 auto;
    padding: ${(props) => props.styles.padding};
    max-height: 60vh;
    overflow: auto;
  }
`;
