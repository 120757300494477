import styled from "styled-components";
import { ModalHeaderThemeType } from "../../../../types";

type ModalHeaderThemeStyles = {
  styles: ModalHeaderThemeType;
};

export const ModalHeaderDiv = styled.div.attrs(() => ({
  className: "bcr-modal-header",
}))<ModalHeaderThemeStyles>`
  &&& {
    background-color: ${(props) => props.styles.background};
    border-bottom: ${(props) => `1px solid ${props.styles.bottomBorderColor}`};
    color: ${(props) => props.styles.fontColor};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${(props) => props.styles.padding};
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    font-weight: ${(props) => props.styles.fontWeight};
    font-size: ${(props) => props.styles.fontSize};
    font-style: ${(props) => props.styles.fontStyle};
    font-family: ${(props) => props.styles.fontFamily ?? undefined};
  }
`;
