import styled from "styled-components";

export type ModalFooterStyles = {
  borderTopColor?: string;
  background?: string;
  fontColor?: string;
  padding?: string;
};

type ModalFooterThemeStyles = {
  styles: ModalFooterStyles;
  justifyContent?: string;
};

export const ModalFooterDiv = styled.div.attrs(() => ({
  className: "bcr-modal-footer",
}))<ModalFooterThemeStyles>`
  &&& {
    background: ${(props) => props.styles.background};
    border-top: 1px solid ${(props) => props.styles.borderTopColor};
    color: ${(props) => props.styles.fontColor};
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    padding: ${(props) => props.styles.padding};
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
`;
