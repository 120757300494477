import React from "react";
import { ModalBodyThemeType } from "types";
import { useTheme } from "../../../../hooks/useTheme";
import { ModalBodyDiv } from "./ModalBodyStyles";

export type ModalBodyProps = {
  children: React.ReactNode;
  styles?: ModalBodyThemeType;
};

const ModalBody: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ children, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride = { ...Theme.modal.body, ...styles };
  return <ModalBodyDiv styles={StylesOverride}>{children}</ModalBodyDiv>;
};

ModalBody.displayName = "ModalBody";

export default ModalBody;
